import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth } from "./api/Auth/useAuth";
import PageResultadosVidaFutura from "./components/Simulador/vida/Vida-Futura/ResultadosVidaFutura";
import PageSimuladorVidaFutura from "./components/Simulador/vida/Vida-Futura/SimuladorVidaFutura";
import Navegacao from "./components/MainPage2";
import Profile from "./components/Profile/Profile";
import SignIn from "./components/Auth/SignIn";
import Leads from "./components/leads2";
import AgentesPage from "./components/Agentes/agentes";
import AgenteView from "./components/Agentes/AgenteView";
import SideMenu from "./components/Appheader/SideMenu";
import ReportsPage from "./components/Relatorios/Relatorio2";
import RelatorioView from "./components/Relatorios/RelatorioView";
import ChooseSim from "./components/Simulador/ChooseSim";
import Habitacao from "./components/Simulador/vida/Habitacao/SimuladorHabitacao";
import ResultadoSeguroHabit from "./components/Simulador/vida/Habitacao/ResultadosHabitacao";
import PageSimuladorVidaPleno from "./components/Simulador/vida/Pleno/SimuladorPleno";
import SimPageResultadosVidaFutura from "./components/Simulador/sales_sim/vida/Vida-Futura/ResultadosVidaFutura";
import SimPageResultadoSeguroHabit from "./components/Simulador/sales_sim/vida/Habitacao/ResultadosHabitacao";
import TipoChange from "./components/Profile/admin/TipoChange";
import TabelaComponent from "./components/Simulador/vida/Pleno/ResultadoPleno";
import SimTabelaComponent from "./components/Simulador/sales_sim/vida/Pleno/ResultadoPleno";
import PageResultadosSaude from "./components/Simulador/vida/saude/ResultadoSaude";
import PageResultadosSaude2 from "./components/Simulador/vida/saude/ResultadoSaude2";
import PrivateRoute from "./components/Auth/components/PrivateRoute";
import NotFoundPage from "./components/NotFound";
import PatchNotes from "./components/patchNotes";
import Maintenance from "./components/manutencao";

function Main() {
  const { isAuthenticated } = useAuth();
  const isUnderMaintenance = false; // Simula estado de manutenção
  if (isUnderMaintenance) {
    return (
      <Routes>
        <Route path="*" element={<Maintenance />} />
      </Routes>
    );
  }
  
  if (!isAuthenticated) {
    return <SignIn />;
  }
  

  return (
    <>
      <Routes>
        <Route path="/" element={<SideMenu />}>
          <Route index element={<Navegacao />} />
          {/* private route */}
          <Route
            path="/profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/simuladores"
            element={
              <PrivateRoute>
                <ChooseSim />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/leads"
            element={
              <PrivateRoute>
                <Leads />
              </PrivateRoute>
            }
          />
          <Route
            path="/leads/:filters"
            element={
              <PrivateRoute>
                <Leads />
              </PrivateRoute>
            }
          />
          <Route
            path="/agentes"
            element={
              <PrivateRoute>
                <AgentesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/agentes/:id"
            element={
              <PrivateRoute>
                <AgenteView />
              </PrivateRoute>
            }
          />
          <Route
            path="/relatorios"
            element={
              <PrivateRoute>
                <ReportsPage />
              </PrivateRoute>
            }
          />
          
          {/* RelatorioView */}
          <Route
            path="/relatorio/:id"
            element={
              <PrivateRoute>
                <RelatorioView />
              </PrivateRoute>
            }
          />

          {/* simuladores vida */}
          <Route
            path="/simulador/vida-futura"
            element={
              <PrivateRoute>
                <PageSimuladorVidaFutura />
              </PrivateRoute>
            }
          />
          <Route
            path="/simulador/vida-habitacao"
            element={
              <PrivateRoute>
                <Habitacao />
              </PrivateRoute>
            }
          />
          <Route
            path="/simulador/vida-pleno"
            element={
              <PrivateRoute>
                <PageSimuladorVidaPleno />
              </PrivateRoute>
            }
          />
          <Route
            path="/vida-habitacao/:id"
            element={
              <PrivateRoute>
                <ResultadoSeguroHabit />
              </PrivateRoute>
            }
          />
          <Route
            path="/vida-futura/:id"
            element={
              <PrivateRoute>
                <PageResultadosVidaFutura />
              </PrivateRoute>
            }
          />

          {/* {/* path for sim from simuladores must be vida-futura-sim/:id  */}
          <Route
            path="/vida-futura/sim/:id"
            element={
              <PrivateRoute>
                <SimPageResultadosVidaFutura />
              </PrivateRoute>
            }
          />
          <Route
            path="relatorio/vida-futura/sim/:id/:simId"
            element={
              <PrivateRoute>
                <SimPageResultadosVidaFutura />
              </PrivateRoute>
            }
          />
          <Route
            path="/vida-habitacao/sim/:id"
            element={
              <PrivateRoute>
                <SimPageResultadoSeguroHabit />
              </PrivateRoute>
            }
          />
          <Route
            path="relatorio/vida-habitacao/sim/:id/:simId"
            element={
              <PrivateRoute>
                <SimPageResultadoSeguroHabit />
              </PrivateRoute>
            }
          />
          <Route
            path="/vida-pleno/:id"
            element={
              <PrivateRoute>
                <TabelaComponent />
              </PrivateRoute>
            }
          />
          <Route
            path="/vida-pleno/sim/:id"
            element={
              <PrivateRoute>
                <SimTabelaComponent />
              </PrivateRoute>
            }
          />

          {/* route to vida-saude/:id but with dados */}
          <Route path="/saude/:id" element={<PageResultadosSaude />} />
          <Route
            path="tipo"
            element={
              <PrivateRoute>
                <TipoChange />
              </PrivateRoute>
            }
          />
        </Route>
        <Route
            path="notas-de-atualizacao"
            element={
                <PatchNotes />
            }
          />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </>
  );
}

export default Main;
