import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Label } from "recharts";
import { useNavigate } from "react-router-dom";
import { Card } from "antd";

const renderCustomActiveShape = (props: any): React.ReactElement => {
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    fill,
    payload,
    percent,
    value,
  } = props;

  const RADIAN = Math.PI / 180;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">
        {`${payload.name}: ${value}`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`(Rate ${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const SalesPieChart: React.FC<{ data: { name: string; value: number; color: any }[] }> = ({ data }) => {
  const navigate = useNavigate();

  // Filtra dados para o primeiro gráfico (abertas, outras)
  const openLeadsData = data.filter((entry) =>
    ["Aberta", "Entregue", "Reagendada"].includes(entry.name)
  );

  // Filtra dados para o segundo gráfico (fechadas, outras)
  const closedLeadsData = data.filter((entry) =>
    ["Fechada","Não atendeu","Falsa","Repetida","Cancelada","Outro"].includes(entry.name)
  );

  
  return (
    <>
      {/* Se houver dados para o gráfico, exibe o cartão; caso contrário, exibe um cartão de boas-vindas */}
      {data.length > 0 ? (
        <Card title="Estado das Leads">
          <ResponsiveContainer width="100%" height={500}>
            <PieChart>
              {openLeadsData.length !== 0 && (
                <Pie
                  data={openLeadsData}
                  cx={closedLeadsData.length !== 0 ? "25%" : "50%"}
                  cy="50%"
                  outerRadius={120}
                  dataKey="value"
                  onClick={() => {
                    navigate("/leads/abertas");
                  }}
                  label={renderCustomActiveShape}
                >
                  {openLeadsData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                  <Label
                    value="Leads Abertas"
                    position="center"
                    className="text-2xl font-extrabold"
                    fill="#fff"
                    style={{ textShadow: "0 0 5px #000" }}
                  />
                </Pie>
              )}

              {closedLeadsData.length !== 0 && (
                <Pie
                  data={closedLeadsData}
                  cx={openLeadsData.length !== 0 ? "75%" : "50%"}
                  cy="50%"
                  outerRadius={120}
                  dataKey="value"
                  label={renderCustomActiveShape}
                  onClick={() => {
                    navigate("/leads");
                  }}
                >
                  {closedLeadsData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                  <Label
                    value="Leads Fechadas"
                    position="center"
                    className="text-2xl font-extrabold"
                    fill="#fff"
                    style={{ textShadow: "0 0 5px #000" }}
                  />
                </Pie>
              )}
            </PieChart>
          </ResponsiveContainer>
        </Card>
      ) : (
        <Card title="Bem vindo!">
          <div className="flex flex-col items-center justify-center">
            <p className="text-2xl font-extrabold">Sem leads!</p>
            <p className="text-2xl font-extrabold">Aguarde até receber uma lead</p>
          </div>
        </Card>
      )}
    </>
  );
};

export default SalesPieChart;
