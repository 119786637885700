import { createContext, useContext } from "react";

interface UserData {
  id: number;
  username: string;
  email: string;
  confirmed: boolean;
  blocked: boolean;
  role: {
    id: number;
    name: string;
    description: string;
    type: string;
  };
  tipo: {
    id: number;
    nivel: string;
    cor: string;
    level:any;
  };
  phone: string;
  equipa: {
    id: number;
    nome_equipa: string;
    zona: {
      id: number;
      nome_zona: string;
    };
  };
}

interface EquipaData {
  id: number;
  attributes: {
    nome_equipa: string;
    user_permissions_user: {
      id: number;
      username: string;
    };
    zona: {
      id: number;
      nome_zona: string;
    };
  };
}

interface ZonaData {
  id: number;
  attributes: {
    nome_zona: string;
    user_permissions_user: {
      id: number;
      username: string;
    };
  };
}

interface ResultItem {
  age: number;
  yearK: number;
  capital: number;
  papr: number;
  pacr: number;
  cmp: number;
  prto: number;
  prtoAgv: number;
  prto_Monthly: number;
  prtoAgv_Monthly: number;
  pac: number;
  papf: number;
  pmaf: number;
  rgpf: number;
  rgpf_Death: number;
}

interface ResultadoSimulacao {
  name: string;
  birthday: string;
  contract: string;
  duration: number;
  radAwardCapital: string;
  award: number;
  capital: number;
  payment: number;
  percentage: number;
  numberIterations: number;
  age: number;
  guaranteedRate: number;
  indexing: number;
  result: ResultItem[];
  capital_Ma: number;
  capital_Mac: number;
}

interface Data {
  attributes: {
    nome: string;
    email: string;
    phone: string;
    data_entrega: string;
    resultado_simulacao: any;
    createdAt: string;
    updatedAt: string;
    uuid: string;
    user: {
      data: {
        attributes: {
          username: string;
        };
        id: number;
      };
    };
    estado: any;
    tipo_simulacao: any;
    dados_simulacao: any;
    reagendado: boolean;
    data_reagendamento: any;
    // may have many observacoes
    observacoes: observacoes[];
  };
}

interface RelatorioData {
  id: number;
  attributes: {
    createdAt:any;
    simulacao: Data;
    estado: any;
    Observacoes: string;
    Fechado: boolean;
    reagendado: boolean;
    outros: string;
  };
}

// observacao interface -> user -> simuladorData -> texto

interface observacoes {
  id: number;
  attributes: {
    user: UserData;
    texto: string;
    simulacao: Data;
  };
}

export const AuthContext = createContext<{
  user: UserData | null;
  setUser: (user: UserData | null) => void;
  isLoading: boolean;
  equipas: EquipaData[];
  zonas: ZonaData[];
  simuladorData: Data[];
  users: UserData[];
  triggerUpdate: () => void;
  tipos: any[];
}>({
  user: null,
  setUser: () => {},
  isLoading: false,
  equipas: [],
  zonas: [],
  simuladorData: [],
  users: [],
  tipos: [],
  triggerUpdate: () => {},
});


export const useAuthContext = () => useContext(AuthContext);
