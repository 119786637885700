import React, { useEffect, useState } from "react";
import { Modal, Select } from "antd";
import { useAuthContext } from "../context/AuthContext";

const EditLeadModal = ({ record, visible, onCancel, onSave }: any) => {
  const [selectedZona, setSelectedZona] = useState("");
  const [selectedEquipa, setSelectedEquipa] = useState("");
  const [selectedUser, setSelectedUser] = useState<string | null>(null); // Pode ser string ou null
  const [selectedEstado, setSelectedEstado] = useState("");
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null); // Pode ser string ou null

  useEffect(() => {
    setSelectedZona(
      record?.attributes?.user?.data?.attributes?.equipa?.data?.attributes?.zona
        ?.data?.attributes?.nome_zona
    );
    setSelectedEquipa(
      record?.attributes?.user?.data?.attributes?.equipa?.data?.attributes
        ?.nome_equipa
    );
    setSelectedUser(record?.attributes?.user?.data?.attributes?.username || null);
    setSelectedEstado(record?.attributes?.estado);
    setSelectedUserId(record?.attributes?.user?.data?.id || null);
  }, [record]);

  const { users, user } = useAuthContext();

  const handleUserChange = (value: any) => {
    if (value === "null") {
      setSelectedUser(null); // Define como "Sem Utilizador"
      setSelectedUserId(null);
    } else {
      setSelectedUser(value);
      setSelectedUserId(value);
    }
  };

  const correctLevel = (level: number) => {
    if (level === 0) {
      return "Automação";
    } else if (level === 1) {
      return "Admin";
    } else if (level === 2) {
      return "Diretor";
    } else if (level === 3) {
      return "Coordenador";
    } else if (level === 4) {
      return "Gestor";
    } else if (level === 5) {
      return "Agente";
    } else {
      return "N/A";
    }

  }

  return (
    <Modal
      title="Editar"
      open={visible}
      onCancel={onCancel}
      onOk={() => {
        onSave({
          estado: selectedEstado,
          user: selectedUserId
        });
      }}
      okButtonProps={{ className: "bg-blue-500 text-white" }}
    >
      <div>
        <label htmlFor="user">User:</label>
        <Select
          showSearch // Habilita a busca
          value={selectedUser || "null"}
          onChange={handleUserChange}
          style={{ width: "100%" }}
          placeholder="Selecione um usuário"
          optionFilterProp="children"
          filterOption={(input, option) =>
            typeof option?.children === "string" &&
            (option.children as string).toLowerCase().includes(input.toLowerCase())
          }
        >

          {/* Adiciona a opção de "Sem Utilizador" */}
          <Select.Option key="null" value="null">
            Sem Utilizador
          </Select.Option>

          {/* Mapeando os usuários com base no nível */}
          {Array.isArray(users) ? (
            users
              .filter((u) => u.tipo.level > user?.tipo.level || u.tipo.level === 0) // Filtra usuários abaixo do nível do diretor ou nível 0
              .map((user) => (
                <Select.Option key={user.id.toString()} value={user.id}>
                  {user.username} ({correctLevel(user.tipo.level)})
                </Select.Option>
              ))
          ) : (
            <Select.Option
              key={record?.attributes?.user?.data?.id}
              value={record?.attributes?.user?.data?.id}
            >
              {record?.attributes?.user?.data?.attributes?.username}
            </Select.Option>
          )}
        </Select>


      </div>
      {/* Estado */}
      <div>
        <label htmlFor="estado">Estado:</label>
        <Select
          value={selectedEstado}
          onChange={(value) => setSelectedEstado(value)}
          style={{ width: "100%" }}
        >
          <Select.Option value="Aberta">Aberta</Select.Option>
          <Select.Option value="Entregue">Entregue</Select.Option>
          <Select.Option value="Cancelada">Cancelada</Select.Option>
          <Select.Option value="Fechada">Fechada</Select.Option>
          <Select.Option value="Repetida">Repetida</Select.Option>
          <Select.Option value="Falsa">Falsa</Select.Option>
        </Select>
      </div>
    </Modal>
  );
};

export default EditLeadModal;
