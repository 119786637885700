import React from 'react';
import LogoEmpresa from "../assets/img/logo192.png";

const PatchNotes: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen overflow-hidden bg-gray-100">
      <div className="max-w-4xl max-h-screen overflow-auto">
        <div className="flex items-center justify-center mb-8">
          <img src={LogoEmpresa} alt="Logo da Empresa" className="h-24" />
        </div>
        <div className="p-8 bg-white rounded-lg shadow-md">
          <h1 className="mb-8 text-4xl font-bold text-center">Notas da Atualização</h1>
          <div className="mb-12">
            <h2 className="mb-4 text-2xl font-bold">Exibição de Leads</h2>
            <img
              src="https://strapi.realbusinesscenter.wt.pt/uploads/Captura_de_ecra_2024_10_09_153730_9287da1a37.png" // Substitua pela URL da captura de tela
              alt="Exibição de Leads"
              className="w-full my-4 rounded-lg shadow-md"
            />
          </div>

          <div className="mb-12">
            <h2 className="mb-4 text-2xl font-bold">Notificações</h2>
            <img
              src="https://strapi.realbusinesscenter.wt.pt/uploads/imagem_2024_10_09_155207804_ade8fb2b8b.png" // Substitua pela URL da captura de tela
              alt="Notificações"
              className="w-full my-4 rounded-lg shadow-md"
            />
          </div>

          <div className="mb-12">
            <h2 className="mb-4 text-2xl font-bold">Página Principal</h2>
            <img
              src="https://strapi.realbusinesscenter.wt.pt/uploads/Captura_de_ecra_2024_10_09_153658_10e2e5201b.png" // Substitua pela URL da captura de tela
              alt="Página Principal"
              className="w-full my-4 rounded-lg shadow-md"
            />
            <p className="text-sm">- Alterações realizadas na página principal para exibir todos os estados dados deste mês, com filtros e uma melhor experiência de usuário.</p>
          </div>

          <div className="mb-12">
            <h2 className="mb-4 text-2xl font-bold">Leads</h2>
            <img
              src="https://strapi.realbusinesscenter.wt.pt/uploads/Captura_de_ecra_2024_10_09_153848_9fe009b1d7.png" // Substitua pela URL da captura de tela
              alt="Leads"
              className="w-full my-4 rounded-lg shadow-md"
            /><img
              src="https://strapi.realbusinesscenter.wt.pt/uploads/Captura_de_ecra_2024_10_09_153855_863c8d1d58.png" // Substitua pela URL da captura de tela
              alt="Leads"
              className="w-full my-4 rounded-lg shadow-md"
            /><img
              src="https://strapi.realbusinesscenter.wt.pt/uploads/Captura_de_ecra_2024_10_09_153851_4bc5d60d07.png" // Substitua pela URL da captura de tela
              alt="Leads"
              className="w-full my-4 rounded-lg shadow-md"
            />
          </div>

          <div className="mb-12">
            <h2 className="mb-4 text-2xl font-bold">Relatórios</h2>
            <img
              src="https://strapi.realbusinesscenter.wt.pt/uploads/imagem_2024_10_09_155429086_52e0e3e78d.png" // Substitua pela URL da captura de tela
              alt="Relatórios"
              className="w-full my-4 rounded-lg shadow-md"
            />
          </div>

          <div className="mb-12">
            <ul className="mb-6 ml-8 list-disc">
              <li className="mb-4">
                <span className="font-bold">Chamada da API:</span>
                <p className="text-sm">- API chamada diretamente nos componentes, tornando a aplicação mais leve e rápida.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Exibição de Leads:</span>
                <p className="text-sm">- Leads mostradas em incrementos de 10.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Notificações:</span>
                <p className="text-sm">- Visual atualizado e agora exibindo as notificações mais recentes primeiro.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Atualização do .gitignore:</span>
                <p className="text-sm">- Leads reagendadas agora ignoradas.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Filtros:</span>
                <p className="text-sm">- Filtros para leads do mês atual, barra de pesquisa por múltiplos critérios.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Relatórios:</span>
                <p className="text-sm">- Barra de pesquisa e dashboard atualizado.</p>
              </li>
            </ul>
          </div>
          <div className="mb-12">
            <ul className="mb-6 ml-8 list-disc">
              <li className="mb-4">
                <span className="font-bold">Adicionadas simulações de saúde.</span>
                <p className="text-sm">- Havia um problema na forma como a empresa externa enviava os dados das simulações; apenas enviava o nome, e-mail e telefone do utilizador. Estamos aguardando que a empresa externa envie os dados completos da simulação.</p>
                <p className="text-sm">- Foi criada uma página temporária para apresentar os dados enviados, permitindo que os utilizadores contactem os clientes.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Refeito o sistema de criação de relatórios.</span>
                <p className="text-sm">- Agora, o sistema cria até 3000 linhas em vez de 1000. Criada uma API e backend para a criação dos relatórios.</p>
              </li>
              <li className="mb-4 bg-yellow-300">
                <span className="font-bold">Alterada a forma como se pesquisam as leads.</span>
                <p className="text-sm">- Agora as leads são pesquisadas pelas mais recentes. Para administradores, são mostradas as 500 leads mais recentes, tendo pouco ou nenhum impacto nos agentes, mas grande impacto nos administradores, gestores e diretores.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Alterada a forma de apresentação dos relatórios.</span>
                <p className="text-sm">- Relatórios agora ordenados pelos mais recentes, garantindo que os relatórios mais novos sejam sempre exibidos primeiro.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Criado um sistema de segurança para criação de relatórios.</span>
                <p className="text-sm">- Apenas o servidor das leads pode enviar pedidos de criação de relatórios. Todos os dias é criada uma chave, e sempre que um relatório precisa ser criado, a chave deve ser solicitada ao servidor. Após a validação do IP de origem e da chave, o pedido de criação de relatório é feito.</p>
              </li>
            </ul>
          </div>

          <li className="mb-4">
            <span className="font-bold bg-green-300">Alteração visual dos simuladores de saúde, vida pleno e vida habitação.</span>
            <p className="text-sm bg-green-300">- As alterações visuais foram implementadas para melhorar a experiência do utilizador.</p>
            <br />
            <h2 className="mb-4 text-2xl font-bold">Saúde:</h2>
            <img
              src="https://strapi.realbusinesscenter.wt.pt/uploads/Captura_de_ecra_2024_09_30_as_15_07_33_32fac4caa0.png" // Substitua pela URL da captura de tela
              alt="Simulador de Saúde"
              className="w-full my-4"
            />
            <h2 className="mb-4 text-2xl font-bold">Vida Pleno:</h2>
            <img
              src="https://strapi.realbusinesscenter.wt.pt/uploads/Captura_de_ecra_2024_09_30_as_15_08_15_2b58ba1758.png" // Substitua pela URL da captura de tela do vida pleno
              alt="Simulador de Vida Pleno"
              className="w-full my-4"
            />
            <h2 className="mb-4 text-2xl font-bold">Vida Habitação:</h2>
            <img
              src="https://strapi.realbusinesscenter.wt.pt/uploads/Captura_de_ecra_2024_09_30_as_15_08_44_660b806c7a.png" // Substitua pela URL da captura de tela do vida habitação
              alt="Simulador de Vida Habitação"
              className="w-full my-4"
            />
          </li>

          <div className="mb-12">
            <ul className="mb-6 ml-8 list-disc">
              <li className="mb-4 bg-yellow-300">
                <span className="font-bold">Atualização dos endpoints da API para melhorar a funcionalidade.</span>
                <p className="text-sm">- Agora relatórios mostram (caso haja) mais de mil resultados.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Correção dos caminhos de importação em vários componentes para garantir a integridade do sistema.</span>
                <p className="text-sm">Adicionada tradução portuguesa nos date pickers.</p>
              </li>
            </ul>
          </div>
          <div className="mb-12">
            <ul className="mb-6 ml-8 list-disc">
              <li className="mb-4">
                <span className="font-bold">Atualização das opções de paginação em SideMenu.tsx, Relatorio.tsx, agentes.tsx...</span>
                <p className="text-sm">- Alterada a forma de como as leads são distribuídas. Agora, primeiro verifica-se se existe uma lead com o mesmo email da nova lead. Caso exista e seja do mesmo tipo de simulação, a lead é marcada como repetida. Se existir uma lead com o mesmo email, mas de outra simulação, verifica-se se está atribuída a outro utilizador. Em caso afirmativo, a nova lead é atribuída ao mesmo utilizador, evitando assim que duas leads do mesmo utilizador tenham tipos de simulação diferentes.</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">Alteração visual do resultado da simulação de vida habitação. O texto dos dados do cliente agora está a branco.</span>
                <img
                  src="https://strapi.realbusinesscenter.wt.pt/uploads/Screenshot_2024_04_30_at_18_51_35_c1b7e152bb.png"
                  alt="Resultado da simulação de vida habitação"
                  className="w-full my-4"
                />
              </li>
              <li className="mb-4">
                <span className="font-bold">Alteração do tamanho horizontal da tabela onde são apresentadas as leads. O número de leads por página foi alterado para 10 na mesma tabela.</span>
                <p className="text-sm">- Foram removidas da tabela todas as leads com estado final da tabela inicial, bem como as leads com estado reagendado superior a 3 dias. Para ver uma lead, o utilizador deve clicar na checkbox todas. (Inserir captura de ecrã)</p>
              </li>
              <li className="mb-4">
                <span className="font-bold">É possível pesquisar na barra de pesquisa da tabela por email, nome, UUID, telefone, estado, utilizador, categoria e tipo de simulação.</span>
              </li>
              <li className="mb-4">
                <span className="font-bold">Admin: Agora, ao filtrar por utilizador, são mostrados apenas agentes e estão organizados por ordem alfabética.</span>
              </li>
              <li className="mb-4">
                <span className="font-bold">Página de relatórios: A tabela de relatórios foi alterada para mostrar apenas 5 por página, mas com a opção de aumentar.</span>
                <p className="text-sm text-center underline bg-yellow-300">- *PARA IMPLEMENTAR* Implementação de barra de pesquisa nos relatórios.</p>
              </li>
            </ul>
          </div>
          <div>
            <ul className="ml-8 list-disc">
              <li className="mb-4">
                <span className="font-bold">Refatoração dos endpoints da API, atualização da estrutura de dados da simulação e correção de caminhos de importação.</span>
                <p className="text-sm">- Adicionada a possibilidade de verificar as observações de distribuição na página das leads.</p>
                <p className="text-sm">- Corrigida a data de nascimento da simulação.</p>
                <p className="text-sm">- Corrigido o erro ao clicar na referência na página de relatórios, agora redireciona para o local correto.</p>
                <img
                  src="https://strapi.realbusinesscenter.wt.pt/uploads/Screenshot_2024_04_30_at_16_39_57_9a42b55448.png"
                  alt="Resultado da simulação de vida habitação"
                  className="w-full my-4"
                />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatchNotes;
